<template>
  <ul>
    <li class="contact-section">
      <div class="section-title flex-row align-center">
        <div class="section-icon is-flex align-center">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="info-circle"></font-awesome-icon>
          </font-awesome-layers>
        </div>
        <span class="has-text-weight-bold">{{ $t('contact.title.info') }}</span>
      </div>
      <div class="section-content">
        <p>{{ companyName }}</p>
      </div>
    </li>
    <li class="contact-section">
      <div class="section-title flex-row align-center">
        <div class="section-icon is-flex align-center">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
          </font-awesome-layers>
        </div>
        <span class="has-text-weight-bold">{{ $t('contact.title.address') }}</span>
      </div>
      <div class="section-content">
        <p>{{ companyAddress }}</p>
      </div>
    </li>
    <li class="contact-section">
      <div class="section-title flex-row align-center">
        <div class="section-icon is-flex align-center rotate-quarter">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="phone"></font-awesome-icon>
          </font-awesome-layers>
        </div>
        <span class="has-text-weight-bold">{{ $t('contact.title.phone') }}</span>
      </div>
      <div class="section-content">
        <div class="flex-row">
          <a>{{ companyTelephone }}</a>
        </div>
      </div>
    </li>
    <li class="contact-section">
      <div class="section-title flex-row align-center">
        <div class="section-icon is-flex align-center">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="envelope"></font-awesome-icon>
          </font-awesome-layers>
        </div>
        <span class="has-text-weight-bold">{{ $t('contact.title.email') }}</span>
      </div>
      <div class="section-content">
        <a :href="`mailto:${companyMail}`">{{ companyMail }}</a>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    companyName() {
      return this.selectedProject.company_name || this.$t('contact.company');
    },
    companyAddress() {
      return this.selectedProject.company_address || this.$t('contact.address1');
    },
    companyTelephone() {
      return this.selectedProject.company_telephone || '+886-2-26481050 / +886-918883451';
    },
    companyMail() {
      return this.selectedProject.company_mail || 'geosupply.tw@gmail.com';
    },
  },
};
</script>

<style lang="stylus" scoped>
.rotate-quarter
  transform rotate(0.25turn)
.seperator
  margin 0 0.5rem
.contact-section
  .section-title
    .section-icon
      margin-right 1rem
  .section-content
    padding 1rem 2rem 2rem
</style>
